import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';

// import { Main } from 'styles/privacyPolicy';
import 'styles/privacyPolicy.scss';
const PrivacyPolicy = ({ location }) => (
  <Layout location={location}>
    <Meta
      title="Terms and Conditions for Usage 🔍 | ruttl "
      description="Explore the guidelines that govern your usage of our design and collaboration tool. Stay informed and make the most out of your experience on ruttl."
      url="https://ruttl.com/terms-and-conditions/"
    />
    <main className="privacy-policy-styled-main">
      <div className="container">
        <div className="inner-common-hero">
          <h1 className="privacy-head">
            Terms and Conditions
            <br />
            for Usage of ruttl app
          </h1>
        </div>
      </div>

      <section>
        <div className="container">
          <div className="privacy-content">
            <h2>Overview:</h2>
            <p>
              These terms and conditions govern your use of our website and
              service located at https://web.ruttl.com and https://ruttl.com
              (from here on referred to as ruttl) and owned by Brucira Online
              Solutions Pvt. Ltd. (from here on referred to as Brucira). By
              using ruttl and its subdirectories, you agree to, and accept these
              terms and conditions in full. If you disagree with any part of
              this disclaimer, do not use ruttl as that condition prohibits you
              from doing so.
            </p>
            <p>
              ruttl reserves the right to modify these terms at any time, and
              thus, you should check for changes before proceeding to utilize
              the services and content of ruttl. By using this site after
              changes have been made to this document, you agree to accept those
              changes, whether or not you have reviewed them.
            </p>
            <p>
              We may ask you to provide us with Personal Information in order to
              provide the services associated with ruttl. Any and all
              information we collect will not be shared with anyone without your
              permission and will be used in accordance with our&nbsp;
              <Link to="/privacy-policy/">Privacy Policy.</Link>
            </p>
            <p>
              We use cookies to improve your experience and by agreeing to these
              terms, you consent to our&nbsp;
              <Link to="/privacy-policy/">use of cookies.</Link>
            </p>
            <h2>Rights to information published:</h2>
            <p>
              All materials on this site are protected by copyright and
              intellectual property laws and are the property of ruttl, or its
              licensors. Users or visitors are not entitled to access and
              download the materials located on ruttl for any personal,
              non-personal, commercial or non-commercial use. You must not
              reproduce this material on your personal website, blog, social
              network, or any other online source or resource without our
              express approval.
            </p>
            <h2>
              Our rights to remove or reproduce any user-generated material:
            </h2>
            <p>
              For the purposes of this condition, the term “user-generated
              material” shall refer to any information, content, or any other
              media posted in comments or replies, uploaded, emailed, submitted,
              or otherwise communicated to ruttl via any method at any page on
              https://web.ruttl.com, or any other content communication or
              submission medium or forum. &quot;User-generated material&quot;
              does not include any content outlined in the &quot;Proxied
              Content&quot; section below. That content is the property of the
              respective website being proxied and ruttl is not responsible for
              the contents or reliability, and we do not, expressly or
              otherwise, endorse the views and/or content expressed within those
              sites.
            </p>
            <p>
              By sharing any contribution or user-generated content (including
              any text, photographs, graphics, video, audio or any other type of
              media or content) with ruttl, you agree to grant us, free of
              charge, permission to use the material in any way we deem fit
              (including the modification, reproduction, repurposing, or
              deletion of it). You hereby confirm that your contribution is your
              own original work, is not defamatory and does not infringe upon
              any laws of the country from which you are utilizing this site, or
              from the country where the website originated, or from any other
              geographical place in this world. You confirm that you have the
              full rights to accept this condition.
            </p>
            <h2>Site content (quality, accuracy and use)</h2>
            <p>
              Visitors who use this website and rely on any information do so at
              their own risk.
            </p>
            <p>
              This website and the attached documents are provided “as is”
              without warranty of any kind, either express or implied,
              including, but not limited to, the implied warranties of
              merchantability and fitness for a particular purpose. Information
              found on ruttl will not always be up to date and cannot
              necessarily be relied upon.
            </p>
            <p>
              We use third party service providers for analytics and payment
              processing which collect and store data. By using ruttl, you
              hereby forfeit any and all claims, past, present, and future,
              against the owners, officers, employees, contributors,
              administrators, and other staff of ruttl.
            </p>
            <p>
              Users can add publicly available websites, the data of which is
              stored on Google Cloud Platform. All contents of ruttl&apos;s
              platform are the exclusive property of ruttl, and/or the
              proprietary property of its suppliers, affiliates or licensors.
            </p>
            <h2>You shall:</h2>
            <ul>
              <li>
                Ensure that all the information you supply to us through our
                website, or in relation to ruttl, is true, accurate, current,
                complete and non-misleading.
              </li>
              <li>
                Use ruttl solely for the Intended Purpose and in accordance with
                all limitations and requirements set out in these Terms, and
                shall be solely responsible for all activity associated with
                your account, including, without limitation any activity by your
                Invitees and any User Materials.
              </li>
              <li>
                Be authorized to access any website on which you use the service
                and to utilize our proxy technology in connection with such
                website.
              </li>
              <li>
                Notify us immediately of any breach of security or unauthorized
                use of your account.
              </li>
              <li>
                Be solely responsible for obtaining and maintaining all
                equipment and services needed for access to and use of ruttl,
                and for paying all charges related to them.
              </li>
              <li>
                Be solely responsible for keeping a duplicate copy of all User
                Materials. We do not accept any responsibility or liability for
                the loss of your User Materials.
              </li>
              <li>
                Grant us the right to add your name and your company logo to our
                customer list and website, unless otherwise precluded by prior
                written agreement.
              </li>
            </ul>
            <p>
              Additionally, we encourage all customers to comment on the service
              and provide suggestions for improving it. You agree that all such
              comments and suggestions will be non-confidential and that we own
              all rights to use and incorporate them into the service, without
              payment to you.
            </p>
            <h2>You shall not:</h2>
            <ul>
              <li>
                Use ruttl for any illegal purpose or in any way that causes, or
                may cause, damage to any website or impairment of the
                performance, availability or accessibility of any website.
              </li>
              <li>
                Use ruttl in any way that is unlawful, illegal, fraudulent or
                harmful, or in connection with any unlawful, illegal, fraudulent
                or harmful purpose or activity.
              </li>
              <li>
                Use ruttl to copy, store, host, transmit, send, use, publish or
                distribute any material which consists of (or is linked to) any
                spyware, computer virus, Trojan horse, worm, keystroke logger,
                rootkit or other malicious computer software.
              </li>
              <li>
                Attempt to hack, destabilize or adapt ruttl or its source code,
                or transmit worms, viruses or any code of a destructive nature
                to ruttl or its users. You shall not use any high volume
                automated means (including but not limited to robots, spiders,
                and scripts) to access the service.
              </li>
              <li>
                Modify, prepare derivative works of, reverse engineer, reverse
                assemble, disassemble, decompile or otherwise attempt to
                decipher any software code used to provide the services or any
                other aspect of ruttl’s technology.
              </li>
              <li>
                Conduct any systematic or automated data collection activities
                (including without limitation scraping, data mining, data
                extraction and data harvesting) on or in relation to our website
                without our express written consent.
              </li>
              <li>
                Share your ruttl password with any third party or publicly
                disclose it, or permit any third party to access the service
                using Your user information.
              </li>
            </ul>
            <h2>
              External links – limiting liability for links to other websites:
            </h2>
            <p>
              We are not responsible for the contents or reliability of any
              other websites to which we provide a link, and we do not,
              expressly or otherwise, endorse the views and/or content expressed
              within those sites.
            </p>
            <h2>Proxied Content:</h2>
            <p>
              Any information, content, or any other media on third party
              websites accessed through our proxy technology are for the
              purposes of providing ruttl services. That content is the property
              of the respective website being proxied, and ruttl is not
              responsible for the contents or reliability, and we do not,
              expressly or otherwise, endorse the views and/or content expressed
              within those sites.
            </p>
            <p>
              Any information passing through our proxy (including, but not
              limited to usernames, passwords, form submissions or any other
              information entered through a ruttl proxy) is not recorded or
              stored except for the purposes of displaying the content to the
              user requesting it.
            </p>
            <h2>The actions and opinions of other users:</h2>
            <p>
              Some of the content found on the web pages of ruttl and its
              subdirectories may be created by members of the public. The views
              expressed are theirs and unless specifically stated are not those
              of ruttl. We accept no responsibility for any loss or harm
              incurred from the use of this website or any of its information or
              content.
            </p>
            <h2>User accounts</h2>
            <p>
              You may register for a ruttl account by completing and submitting
              the account registration form on our website.
            </p>
            <p>
              You must notify us in writing immediately if you become aware of
              any unauthorised use of your account. You must not use any other
              person&apos;s account to access the website. If you register for
              an account with our website, you will be asked to enter your
              unique email ID and password, and you must keep your password
              confidential. Alternatively, you may use the Google OAuth to sign
              in with your Google Account. If you sign in via Google OAuth, we
              will use your data only for the purpose of logging in to ruttl.
              You must notify us in writing immediately if you become aware of
              any disclosure of your password. You are responsible for any
              activity on our website arising out of any failure to keep your
              password confidential and may be held liable for any losses
              arising out of such a failure.
            </p>
            <p>
              We reserve the right to suspend your account, cancel your account,
              and/or edit your account details, at any time at our sole
              discretion without notice or explanation.
            </p>
            <p>
              You may cancel your account on our website by emailing us at&nbsp;
              <a href="mailto:support@ruttl.com">support@ruttl.com.</a>
            </p>
            <h2>Refund policy:</h2>
            <ul>
              <li>
                For annual billing &amp; lifetime plans (lifetime plans listed
                on our website&apos;s
                <Link to="/pricing/">&nbsp;privilege plans&nbsp;</Link>
                page only), we offer a
                <span style={{ fontWeight: 600 }}>&nbsp;full refund&nbsp;</span>
                within 7 days of your purchase.
              </li>
              <li>
                For monthly billing, we offer
                <span style={{ fontWeight: '600' }}> no refund</span>
                &#44; but you can cancel at any time, and you would not be
                billed again.
              </li>
            </ul>
            <p>
              You may cancel your account upgradation by emailing us at&nbsp;
              <a href="mailto:support@ruttl.com">support@ruttl.com.</a>
            </p>
            <h2>Service damage and availability:</h2>
            <p>
              ruttl (or its parent, Brucira) makes no warranty or claim that
              functions available on this website will be uninterrupted or
              error-free, that defects will be corrected, or that the server
              that makes it available, nor the content itself is and/or will be
              free of viruses, bugs, or other malicious code. You acknowledge
              that it is your responsibility to implement sufficient procedures
              and virus checks (including anti-virus and other security checks)
              to satisfy your particular requirements for the accuracy of data
              input and output and for the security of yourself and the device
              used by you to view any content from this website.
            </p>
            <h2>Legal jurisdiction:</h2>
            <p>
              The Supreme Court of India’s laws shall otherwise govern your use
              of the site, where these terms and conditions are not clear or
              incomplete and you hereby agree to submit to the exclusive
              jurisdiction of the Supreme Court of India.
            </p>
            <p>
              If a provision of these terms and conditions is determined by any
              court or other competent authority to be unlawful and/or
              unenforceable, the other provisions will continue in effect.
              Additionally, if any unlawful and/or unenforceable provision of
              these terms and conditions would be lawful or enforceable if part
              of it were deleted, that part will be deemed to be deleted, and
              the rest of the provision will continue in effect.
            </p>
            <h2>Limited liability:</h2>
            <p>
              ruttl makes no representations about the suitability, reliability,
              availability, timeliness, security or accuracy of its service. To
              the extent permitted by law, the service is provided “as is”
              without warranty or condition of any kind. ruttl, (and its parent
              Brucira) and its officers, employees, contractors or content
              providers shall not be liable for any loss or damage arising from
              or in connection with your use of any content, information,
              function, or service of ruttl from any location within&nbsp;
              <Link to="/">https://ruttl.com</Link>
              &nbsp;or&nbsp;
              <a href="https://web.ruttl.com">https://web.ruttl.com,</a>
              &nbsp;or other related links or locations (such as content feeds,
              links, emails, letters, documents, and other company products or
              correspondence).
            </p>
            <p>
              In no event shall ruttl (and its parent Brucira) be liable for any
              indirect, punitive, special, incidental, or consequential damages,
              including, without limitation, lost profits or business
              opportunities, in each case regardless of the theory on which such
              claims are made and regardless of whether or not ruttl (or its
              parent Brucira) has been made aware of the possibility of such
              damages.
            </p>
            <p>
              Though we make a reasonable effort to maintain the resources of
              this website, they will, from time to time, become out of date, be
              incorrect, erroneous, or otherwise inappropriate. As noted above,
              by using this website you agree that you will not follow any
              instruction, suggestion, step, list, tutorial, or other content on
              this website or any of its content posted elsewhere until you have
              appropriately consulted with a licensed professional who is
              unrelated to this site or its parent company in any way.
            </p>
            <p>END</p>
          </div>
        </div>
      </section>
    </main>
  </Layout>
);

PrivacyPolicy.propTypes = {
  location: PropTypes.object,
};

export default PrivacyPolicy;
